import Vue from 'vue'
import Router from 'vue-router'

import {ROLE_ADMIN, ROLE_BOOKKEEPER, ROLE_PM} from '@/components/utils.js'
import store from './store.js'

const Contractors = () => import('@/views/Contractors.vue')
const Login = () => import('@/views/Login.vue')
const ResetPassword = () => import('@/views/ResetPassword.vue')
const Profile = () => import('@/views/Profile.vue')
const Settings = () => import('@/views/Settings.vue')
const Projects = () => import('@/views/Projects.vue')
const ProjectGroups = () => import('@/views/ProjectGroups.vue')
const ProjectCostsSummary = () => import('@/views/ProjectCostsSummary.vue')
const ProjectCosts = () => import('@/views/ProjectCosts.vue')
const ProjectCostsDetails = () => import('@/views/ProjectCostsDetails.vue')
const Users = () => import('@/views/Users.vue')
const Contracts = () => import('@/views/Contracts.vue')
const Acts = () => import('@/views/Acts.vue')
const SummaryReports = () => import('@/views/SummaryReports.vue')
const Employees = () => import('@/views/Employees.vue')
const EmployeeRates = () => import('@/views/EmployeeRates.vue')
const EmployeeVacations = () => import('@/views/EmployeeVacations.vue')
const OverheadExpenses = () => import('@/views/OverheadExpenses.vue')
const AccountingExpenses = () => import('@/views/AccountingExpenses.vue')
const AccountingIncomes = () => import('@/views/AccountingIncomes.vue')
const TimeEntries = () => import('@/views/TimeEntries.vue')
const SummaryTimeEntries = () => import('@/views/SummaryTimeEntries.vue')

const DEF_REDIRECT = { name: 'project-costs-summary' }

Vue.use(Router)

const acl = (to, from, next) => {
  if (!store.user.id) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else if (to.meta.roles && to.meta.roles.indexOf(store.user.role) == -1) {
    next(false)
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      beforeEnter: acl,
      redirect: DEF_REDIRECT
    }, {
      path: '/login',
      name: 'login',
      meta: { layout: 'no-menu' },
      component: Login
    }, {
      path: '/reset-password/:token',
      name: 'reset-password',
      meta: { layout: 'no-menu' },
      component: ResetPassword,
      props: { token: null }
    }, {
      path: '/profile',
      name: 'profile',
      component: Profile
    }, {
      path: '/settings',
      name: 'settings',
      component: Settings
    }, {
      path: '/project-costs-summary',
      name: 'project-costs-summary',
      beforeEnter: acl,
      component: ProjectCostsSummary,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-s-grid',
        title: 'Информация по проектам',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER, ROLE_PM],
      }
    }, {
      path: '/project-costs',
      name: 'project-costs',
      beforeEnter: acl,
      component: ProjectCosts,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-s-data',
        title: 'Расчет по проекту',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER, ROLE_PM],
      },
      props: { recId: null }
    }, {
      path: '/project-costs-details',
      name: 'project-costs-details',
      beforeEnter: acl,
      component: ProjectCostsDetails,
      meta: {
        show_in_menu: true,
        after_divider: true,
        icon: 'el-icon-tickets',
        title: 'Детальный расчет',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER, ROLE_PM],
      },
      props: { recId: null }
    }, {
      path: '/accounting-income',
      name: 'accounting-incomes',
      beforeEnter: acl,
      component: AccountingIncomes,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-plus',
        title: 'Доходы',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/accounting-income/:recId',
      name: 'accounting-income',
      beforeEnter: acl,
      component: AccountingIncomes,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/accounting-expense',
      name: 'accounting-expenses',
      beforeEnter: acl,
      component: AccountingExpenses,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-minus',
        title: 'Расходы',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/accounting-expense/:recId',
      name: 'accounting-expense',
      beforeEnter: acl,
      component: AccountingExpenses,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/overhead-expense',
      name: 'overhead-expenses',
      beforeEnter: acl,
      component: OverheadExpenses,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-minus',
        title: 'Накладные расходы',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/overhead-expense/:recId',
      name: 'overhead-expense',
      beforeEnter: acl,
      component: OverheadExpenses,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/employee-vacation',
      name: 'employee-vacations',
      beforeEnter: acl,
      component: EmployeeVacations,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-umbrella',
        title: 'Отпуска',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/employee-vacation/:recId',
      name: 'employee-vacation',
      beforeEnter: acl,
      component: EmployeeVacations,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/employees',
      name: 'employees',
      beforeEnter: acl,
      component: Employees,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-user',
        title: 'Сотрудники',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/employee/:recId',
      name: 'employee',
      beforeEnter: acl,
      component: Employees,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/employee-rate',
      name: 'employee-rates',
      beforeEnter: acl,
      component: EmployeeRates,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-money',
        title: 'Ставки сотрудников',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/employee-rate/:recId',
      name: 'employee-rate',
      beforeEnter: acl,
      component: EmployeeRates,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/project-groups',
      name: 'project-groups',
      beforeEnter: acl,
      component: ProjectGroups,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-files',
        title: 'Группы проектов',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/project-group/:recId',
      name: 'project-group',
      beforeEnter: acl,
      component: ProjectGroups,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/summary-time-entries',
      name: 'summary-time-entries',
      beforeEnter: acl,
      component: SummaryTimeEntries,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-time',
        title: 'Часы по проектам',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/project',
      name: 'projects',
      beforeEnter: acl,
      component: Projects,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-suitcase',
        title: 'Проекты redmine',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/project/:recId',
      name: 'project',
      beforeEnter: acl,
      component: Projects,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/users',
      name: 'users',
      beforeEnter: acl,
      component: Users,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-user',
        title: 'Пользователи',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/user/:recId',
      name: 'user',
      beforeEnter: acl,
      component: Users,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/time-entries',
      name: 'time-entries',
      beforeEnter: acl,
      component: TimeEntries,
      meta: {
        show_in_menu: true,
        icon: 'el-icon-timer',
        title: 'Трудозатраты',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/summary-reports',
      name: 'summary-reports',
      beforeEnter: acl,
      component: SummaryReports,
      meta: {
        icon: 'el-icon-eleme',
        title: 'Сводные отчеты',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/summary-report/:recId',
      name: 'summary-report',
      beforeEnter: acl,
      component: SummaryReports,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/acts',
      name: 'acts',
      beforeEnter: acl,
      component: Acts,
      meta: {
        icon: 'el-icon-s-check',
        title: 'Акты',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/act/:recId',
      name: 'act',
      beforeEnter: acl,
      component: Acts,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/contractors',
      name: 'contractors',
      beforeEnter: acl,
      component: Contractors,
      meta: {
        icon: 'el-icon-s-custom',
        title: 'Юрлица/Контрагенты',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/contractor/:recId',
      name: 'contractor',
      beforeEnter: acl,
      component: Contractors,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/contracts',
      name: 'contracts',
      beforeEnter: acl,
      component: Contracts,
      meta: {
        icon: 'el-icon-s-claim',
        title: 'DC договоры',
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: { recId: null }
    }, {
      path: '/contract/:recId',
      name: 'contract',
      beforeEnter: acl,
      component: Contracts,
      meta: {
        roles: [ROLE_ADMIN, ROLE_BOOKKEEPER],
      },
      props: (route) => ({ recId: parseInt(route.params.recId) })
    },

    { path: '*', redirect: DEF_REDIRECT }
  ]
})
