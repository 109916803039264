<template>
  <div class="prj-detail">
    <div class="prj-detail-wrap">
      <div class="prj-detail-header">
        <span>{{title}}</span>
        <button @click="$emit('esc')">
          <i class="el-dialog__close el-icon el-icon-close" />
        </button>
      </div>

      <div class="prj-detail-content">
        <slot />
      </div>
      <div class="prj-detail-footer">
        <div><slot name="footer" /></div>
        <div class="buttons-footer">
          <el-button type="danger" @click="$emit('delete')" v-if="canDelete && $listeners.delete">Удалить</el-button>
          <el-button type="success" @click="$emit('save')" v-if="$listeners.save">{{saveName}}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },

    saveName: {
      type: String,
      default: 'OK'
    },

    canDelete: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.prj-detail {
  background-color: rgba(0,0,0,.2);
  position: absolute;
  top: @header-height;
  right: 0;
  left: @menu-width;
  bottom: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  // align-content: center;
  justify-content: center; 

  .prj-detail-wrap {
    min-width: 200px;
    max-height: calc(100vh - @header-height - 4px);

    background-color: #FFFFFF;
    border: 1px solid #ebeef5;
    color: #303133;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .prj-detail-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 18px 20px;
      border-bottom: 1px solid #ebeef5;
      box-sizing: border-box;  

      button {
        border: none;
        outline: 0;
        cursor: pointer;
        font-size: 18px;
        background: 0 0;
      }
    }

    .prj-detail-content {
      padding: 20px;
      flex: 1 1 auto;
      // overflow: hidden;
      overflow-x: hidden;
    }

    .prj-detail-footer {
      padding: 20px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .buttons-footer {
        padding-right: 10px;
      }
    }
  }
}
</style>
