import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import fecha from 'fecha'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css'

import '@/components'
import '@/layouts'
import store from './store.js'

const DEFAULT_TITLE = 'Контроль проектов'

const $axios = axios.create({
  baseURL: '/admin/api',
  timeout: 10 * 60 * 1000
})

$axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    store.user.logout()
  }
  return Promise.reject(error)
})

store.axios = $axios
store.user.axios = $axios
Vue.prototype.$axios = $axios

fecha.i18n = {
  dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
}

Vue.use(ElementUI, { locale })


Vue.config.productionTip = false

Vue.filter('toCurrency', function (value) {
    if (!value) return value;
    var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB'
    });
    return formatter.format(value);
});

Vue.filter('isoToRuDate', function (value) {
    let parsed_date = new Date(value)
    if (!isNaN(parsed_date.getTime())){
      return new Intl.DateTimeFormat('ru').format(parsed_date)
    }
    return value
});


new Vue({
  router,
  data: { store },
  render: h => h(App),
  watch: {
      $route: {
          immediate: true,
          handler(to, from) {
              let title = to.meta.title ? to.meta.title + ' | ' : ''
              document.title = title + DEFAULT_TITLE
          }
      }
  }
}).$mount('#app')


