<template>
  <div class="prj-header">
    <slot name="pre"><div /></slot>
    <slot></slot>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
@import "../style.less";

.prj-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}
</style>
