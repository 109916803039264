// https://github.com/qgp9/vue-ya-stash

export default {
  install (Vue) {
    Object.defineProperty(Vue.prototype, '$store', {
      get () {
        return this.$root.store
      }
    })

    var StoreAccessor = function (key) {
      return {
        get () {
          return key.split('.').reduce((pValue, cValue) => {
            return pValue[cValue]
          }, this.$root.store)
        },

        set (value) {
          var path = key.split('.')
          var length = path.length - 1
          var store = this.$root.store

          for (var i = 0; i < length; i++) {
            if (store.hasOwnProperty(path[i])) {
              store = store[path[i]]
            }
          }
          store[path[i]] = value
        }
      }
    }

    var registerStore = function (vm) {
      // 1. Check for a store "option" on the component.
      // 2. Check for a store "object" on the root vue model.
      if (typeof vm.$options.store !== 'undefined' && typeof vm.$root.store !== 'undefined') {
        // Initialize the computed option if it hasn't already been initialized.
        if (typeof vm.$options.computed === 'undefined') {
          vm.$options.computed = {}
        }

        // Check if the store option is a non-empty array.
        if (Array.isArray(vm.$options.store)) {
          vm.$options.store.forEach(property => {
            vm.$options.computed[property] = new StoreAccessor(property)
          })
        } else {
          for (var key in vm.$options.store) {
            if (typeof vm.$options.store[key] === 'function') {
              // Handle a function
              vm.$options.computed[key] = new StoreAccessor(vm.$options.store[key]())
            } else if (typeof vm.$options.store[key] === 'string') {
              // Handle a string
              vm.$options.computed[key] = new StoreAccessor(vm.$options.store[key])
            }
          }
        }
      }
    }

    Vue.mixin({
      beforeCreate () {
        registerStore(this)
      }
    })
  }
}
