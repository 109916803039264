import Vue from 'vue'

import PrjDetail from './prj-detail.vue'
import PrjHeader from './prj-header.vue'

import VueStash from './stash.js'

Vue.component('prj-detail', PrjDetail)
Vue.component('prj-header', PrjHeader)

Vue.use(VueStash)
