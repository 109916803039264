<template>
  <div id="app">
    <component :is=layout>
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  }
}
</script>

<style lang="less">
@import "./style.less";

#app {
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: relative;
}
</style>
