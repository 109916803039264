import {ROLE_PM, ROLE_ADMIN, ROLE_BOOKKEEPER} from '@/components/utils.js'

const TOKEN_COOK = 'prjcontrol'


function b64DecodeUnicode (str) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(str), c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))
}

function parseJwt (token) {
  return JSON.parse(
    b64DecodeUnicode(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    )
  )
}

export default {
  axios: null, // init from main.js
  ROLE_PM: ROLE_PM,
  ROLE_ADMIN: ROLE_ADMIN,
  ROLE_BOOKKEEPER: ROLE_BOOKKEEPER,

  _pmList: [], // {value: id, label: name}
  _pmListLoaded: false,

  _projectGroupList: [], // {value: id, label: name, pm_fio: pm.fio, pm_id: pm.id}
  _projectGroupLoaded: false,

  _projectList: [], // {value: id, label: name}
  _projectLoaded: false,

  _employeeList: [], // {value: id, label: name}
  _employeeLoaded: false,

  _contractorList: [], // {value: id, label: name}
  _contractorLoaded: false,

  _contractList: [], // {value: id, label: name}
  _contractLoaded: false,

  _legalTypeList: [], // {value: id, label: name}
  _legalTypeLoaded: false,

  _actReportTypeList: [], // {value: id, label: name}
  _actReportTypeLoaded: false,

  _workKindList: [], // {value: id, label: name}
  _workKindLoaded: false,

  get pmList () {
    if (this.axios && !this._pmListLoaded) {
      this.axios.get('/pm-list')
        .then(response => {
          this._pmList = response.data.data
          this._pmListLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._pmList
  },

  set pmList (val) {
    this._pmList = []
    this._pmListLoaded = false
  },

  get projectGroupList () {
    if (this.axios && !this._projectGroupLoaded) {
      const self = this
      this.axios.get('/project-group-list')
        .then(response => {
          let _projectGroupList = response.data.data
          if (self.user.role == ROLE_PM) {
              _projectGroupList = _projectGroupList.filter((prj) => prj.pm_id == self.user.id)
          }
          this._projectGroupList = _projectGroupList
          this._projectGroupLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._projectGroupList
  },

  set projectGroupList (val) {
    this._projectGroupList = []
    this._projectGroupLoaded = false
  },

  get projectGroupTypeList () {
    if (this.axios && !this._projectGroupTypeLoaded) {
      this.axios.get('/project-group-types')
        .then(response => {
          this._projectGroupTypeList = response.data.data
          this._projectGroupTypeLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._projectGroupTypeList
  },

  set projectGroupTypeList (val) {
    this._projectGroupTypeList = []
    this._projectGroupTypeLoaded = false
  },

  get projectList () {
    if (this.axios && !this._projectListLoaded) {
      this.axios.get('/project-list')
        .then(response => {
          this._projectList = response.data.data
          this._projectLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._projectList
  },

  set projectList (val) {
    this._projectList = []
    this._projectListLoaded = false
  },

  get contractorList () {
    if (this.axios && !this._contractorLoaded) {
      this.axios.get('/contractor-list')
        .then(response => {
          this._contractorList = response.data.data
          this._contractorLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._contractorList
  },

  set contractorList (val) {
    this._contractorList = []
    this._contractorLoaded = false
  },

  get contractList () {
    if (this.axios && !this._contractLoaded) {
      this.axios.get('/contract-list')
        .then(response => {
          this._contractList = response.data.data
          this._contractLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._contractList
  },

  set contractList (val) {
    this._contractList = []
    this._contractLoaded = false
  },

  get legalTypeList () {
    if (this.axios && !this._legalTypeLoaded) {
      this.axios.get('/legal-type-list')
        .then(response => {
          this._legalTypeList = response.data.data
          this._legalTypeLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._legalTypeList
  },

  get actReportTypeList () {
    if (this.axios && !this._actReportTypeLoaded) {
      this.axios.get('/act-report-type-list')
        .then(response => {
          this._actReportTypeList = response.data.data
          this._actReportTypeLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._actReportTypeList
  },

  get workKindList () {
    if (this.axios && !this._workKindLoaded) {
      this.axios.get('/employee-work-kinds')
        .then(response => {
          this._workKindList = response.data.data
          this._workKindLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._workKindList
  },

  get employeeList () {
    if (this.axios && !this._employeeLoaded) {
      this.axios.get('/employee-list')
        .then(response => {
          this._employeeList = response.data.data
          this._employeeLoaded = true
        })
        .catch(err => {
          console.log(err)
        })
    }
    return this._employeeList
  },

  set employeeList (val) {
    this._employeeList = []
    this._employeeLoaded = false
  },

  user: {
    axios: null, // init from main.js

    _token: null,
    _id: null,
    _fio: null,
    _email: null,
    _role: null,    

    init () {
      if (!this._token) {
        this._token = localStorage.getItem(TOKEN_COOK)
        this.setAxiosHeader()
      }
      if (this._token) {
        const data = parseJwt(this._token)
        this._id = data.id
        this._fio = data.fio
        this._email = data.email
        this._role = data.role
      }
    },

    logout () {
      this.token = ''
      this._id = null
      this._fio = null
      this._email = null
      this._role = null
      window.location = '/login'
    },

    setAxiosHeader () {
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this._token
    },

    get token () {
      if (!this._token) {
        this.init()
      }
      return this._token
    },

    set token (val) {
      if (val) {
        this._token = val
        localStorage.setItem(TOKEN_COOK, val)
      } else {
        this._token = ''
        localStorage.removeItem(TOKEN_COOK)
      }
      this.setAxiosHeader()
    },

    get fio () {
      if (!this._fio) {
        this.init()
      }
      return this._fio
    },

    set fio (value) {
      this._fio = value
    },

    get email () {
      if (!this._email) {
        this.init()
      }
      return this._email
    },

    set email (value) {
      this._email = value
    },

    get role () {
      if (this._role==null) {
        this.init()
      }
      return this._role
    },

    get id () {
      if (!this._id) {
        this.init()
      }
      return this._id
    },

  }
}
