import fecha from 'fecha'

const ISO_DATE = 'YYYY-MM-DD'
const RU_DATE = 'D MMM YYYY'
const ISO_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss'
const RU_DATE_TIME = 'D MMM YYYY, HH:mm'

const ROLE_ADMIN = 1
const ROLE_PM = 2
const ROLE_BOOKKEEPER = 3

const ROLE_ADMIN_NAME = 'Администратор'
const ROLE_PM_NAME = 'Менеджер проекта'
const ROLE_BOOKKEEPER_NAME = 'Бухгалтер'

const roleOptions = [
  {value: ROLE_ADMIN, label: ROLE_ADMIN_NAME},
  {value: ROLE_PM, label: ROLE_PM_NAME},
  {value: ROLE_BOOKKEEPER, label: ROLE_BOOKKEEPER_NAME}
]

const roleNames = {
  [ROLE_ADMIN]: ROLE_ADMIN_NAME,
  [ROLE_PM]: ROLE_PM_NAME,
  [ROLE_BOOKKEEPER]: ROLE_BOOKKEEPER_NAME
}

function ruDate (dt) {
  return fecha.format(fecha.parse(dt, ISO_DATE), RU_DATE, fecha.i18n)
}

function ruDateTime (dt) {
  if (dt) {
    return fecha.format(fecha.parse(dt, ISO_DATE_TIME), RU_DATE_TIME, fecha.i18n)
  }
}

function isoDate (dt) {
  return fecha.format(dt, ISO_DATE, fecha.i18n)
}

function fromIsoDate (dt) {
  return fecha.parse(dt, ISO_DATE)
}

function formatMoney (amount) {
  const decimalCount = 2
  const thousands = " "
  const decimal = "."
  try {
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.error(e)
  }
}

function formatHours (value) {
  return Math.round((value || 0) * 100 ) / 100
}

export { ROLE_ADMIN, ROLE_PM, ROLE_BOOKKEEPER, roleOptions, roleNames, ruDateTime, ruDate, isoDate, fromIsoDate, formatMoney, formatHours }
