<template>
  <el-container class="index" v-if="user.id">
    <el-aside width="250px">
      <el-header><a href="/" class="main-link">Контроль Проектов</a></el-header>

      <el-menu router :default-active="$route.path">
        <template v-for="(item, index) in showedMenu">
          <el-menu-item :key="index" :index="item.path" @click="selectMenu(item)">
            <i :class="item.meta.icon"></i>
            <span>{{item.meta.title}}</span>
          </el-menu-item>
          <el-divider v-if="item.meta.after_divider" border-style="dashed" />
        </template>
      </el-menu>
    </el-aside>
  
    <el-container>
      <el-header style="text-align: right; font-size: 12px;">
        <el-dropdown @command="selectDropdownMenu">
          <span class="el-dropdown-link">
            {{user.fio}}<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user" command="profile">Профиль</el-dropdown-item>
            <el-dropdown-item icon="el-icon-s-tools" command="settings">Настройки</el-dropdown-item>
            <el-dropdown-item icon="el-icon-right" command="logout">Выйти</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </el-container>

  <div v-else />
</template>

<script>
export default {
  store: ['user'],

  computed: {
    showedMenu () {
      return this.$router.options.routes.filter((item) => {
        return item.meta && item.meta.icon && item.meta.show_in_menu && (!item.meta.roles || item.meta.roles.indexOf(this.user.role) > -1)
      })
    }
  },

  methods: {

    selectMenu (item) {
      // NOTE: для того чтобы после применения GET-фильтров их сбросить
      location.href = item.path
    },

    selectDropdownMenu (val) {
      switch(val) {
        case 'profile':
          this.$router.push({ name: 'profile' })
          break
        case 'settings':
          this.$router.push({ name: 'settings' })
          break
        case 'logout':
          this.user.logout()
          break
      }
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.index {
  height: 100%;
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 52px;
    height: @header-height !important;

    .main-link {
      text-decoration: none;
    }

    .el-dropdown-link {
      cursor: pointer;
    }    
  }
  .el-aside {
    .el-divider--horizontal {
      margin: 0;
      border-top-style: dashed;
      border-top-color: lightgray;
      background: transparent;
    }
  }
}
</style>
